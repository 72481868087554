import * as React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Typical from "react-typical";
import Seo from "../../components/seo";
import Navbar from "../../components/navbar";
import Layout from "../../components/layout";

const BlogPost = ({ data }) => {
  const hero = getImage(data.mdx.frontmatter.hero);

  return (
    <Layout>
      <article>
        <Seo
          title={data.mdx.frontmatter.title}
          description={data.mdx.frontmatter.abstract}
          vercel={{
            title: data.mdx.frontmatter.title,
            subtitle: data.mdx.frontmatter.subtitle,
            tags: data.mdx.frontmatter.tags,
          }}
        />
        <Navbar backNavbar={true} />
        <header>
          <p className="fullwidth">
            <GatsbyImage
              image={hero}
              alt={data.mdx.frontmatter.hero_alt}
            ></GatsbyImage>
          </p>
          <h1>{data.mdx.frontmatter.title}</h1>
          <p className="subtitle">
            <Typical steps={[data.mdx.frontmatter.subtitle]} wrapper="span" />
          </p>
          <h3>{data.mdx.frontmatter.author} </h3>
          <span>
            <em>{data.mdx.frontmatter.date}</em>—
            {data.mdx.fields.readingTime.text}
          </span>
        </header>
        <section>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        author
        abstract
        tags
        date(formatString: "MMMM D, YYYY")
        hero {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_alt
      }
      body
      fields {
        readingTime {
          text
        }
      }
      slug
    }
  }
`;

export default BlogPost;
